import React from 'react';
import Img from 'react-image';
import LazyLoad from 'react-lazyload';
import {
  updateParameters, canUseWebP, isServerSide, isRetina, cleanValues,
} from 'core/utils';

const hasWebP = canUseWebP();
const dpr = (isServerSide() || isRetina()) ? 2 : 1;

interface Props {
  source: string;
  imageClasses?: string;
  loader?: JSX.Element | null;
  unloader?: JSX.Element | null;
  onLoad?: any;
  transform?: Record<string, any> | null;
  attributes?: Record<string, any> | null;
  lazyLoadOffset?: number;
  minWidth?: number;
  minHeight?: number;
}

const Image: React.FunctionComponent<Props> = ({
  source,
  imageClasses = '',
  loader = null,
  unloader = null,
  transform = null,
  attributes = null,
  lazyLoadOffset = 0,
  minWidth = 2,
  minHeight = 2,
}) => {
  const checkImage = ({ target }): void => {
    const image = target;
    if (image.naturalWidth < minWidth || image.naturalHeight < minHeight) {
      image.className = 'hidden';
    }
  };

  const parameters: Record<string, any> = {
    ...transform,
    url: source,
    n: -1,
    default: 'https://news.knowledia.com/images/alpha_1px.png',
  };
  if (hasWebP) {
    parameters.output = 'webp';
  }
  if (!parameters.dpr) {
    parameters.dpr = dpr;
  }
  const transformed = updateParameters('https://wsrv.nl/', cleanValues(parameters));

  return (
    <LazyLoad once offset={lazyLoadOffset} height={parameters.height} placeHolder={loader}>
      <Img
        src={[transformed, source]}
        className={`${imageClasses}`}
        loader={loader}
        unloader={unloader}
        onLoad={checkImage}
        {...cleanValues(attributes)}
      />
    </LazyLoad>
  );
};

export default Image;
