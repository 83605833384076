import React, { useState, ReactElement } from 'react';
import { useRouter } from 'next/router';
import Autosuggest from 'react-autosuggest';
import Image from 'components/Image';
import useLocale from 'hooks/useLocale';
import {
  capitalizeFirstLetter,
  capitalize,
  getAvatarURL,
  getLinkProps,
  getFirst,
  getLanguagesPaths,
  truncate,
} from 'core/utils';
import { autocompleteTopics } from 'core/services';
import SearchIcon from '../public/images/icons/search.svg';
import CloseSolid from '../public/images/icons/close-solid.svg';
import SearchKeySlash from '../public/images/icons/search-key-slash.svg';
import Photo from '../public/images/icons/photo.svg';

interface Props {
  query: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReset: (event: React.SyntheticEvent) => void;
  setQuery: (State) => void;
  focusSearch: () => void;
  blurSearch: () => void;
  onSearchFocus: () => void;
  onSearchBlur: () => void;
  isSearchFocused: boolean;
}

const SearchBar: React.FunctionComponent<Props> = ({
  query,
  handleSubmit,
  handleOnChange,
  handleReset,
  setQuery,
  focusSearch,
  blurSearch,
  onSearchFocus,
  onSearchBlur,
  isSearchFocused,
}) => {
  const router = useRouter();
  const { t, interfaceLanguage } = useLocale();
  const [suggestions, setSuggestions] = useState([]);
  const displayClose = query !== '';
  const inputProps = {
    id: 'search',
    className: 'block w-full px-3 py-2 pl-10 pr-10 leading-normal font-semibold text-gray-700 dark:text-dark-top-light placeholder-gray-500 dark:placeholder-dark-top-medium bg-white dark:bg-dark-medium border border-gray-400 dark:border-dark-light appearance-none focus:outline-none focus:border-indigo-400 dark:focus:border-dark-light element dark:bg-dark-medium',
    name: 'search',
    type: 'search',
    placeholder: capitalizeFirstLetter(t('search')),
    spellCheck: false,
    autoComplete: 'off',
    onChange: handleOnChange,
    onFocus: onSearchFocus,
    onBlur: onSearchBlur,
    value: query,
  };
  const imageClasses = 'object-cover w-8 h-8 rounded-full shadow-inner inline-block border border-gray-300 mr-1 dark:border-dark-light';
  const loadingPhoto = <div className={`flex items-center align-center ${imageClasses}`}><Photo className="block w-4 h-4 m-auto text-gray-200 fill-current dark:text-dark-light" /></div>;

  const getSuggestionValue = (): string => '';

  const renderSuggestion = (suggestion: Record<string, any>): ReactElement => {
    const label = truncate(capitalize(getFirst(suggestion, getLanguagesPaths('label.$language[0].value', interfaceLanguage), '')), 50, true);
    const description = truncate(capitalizeFirstLetter(getFirst(suggestion, getLanguagesPaths('description.$language[0].value', interfaceLanguage), '')), 50, true);

    return (
      <div key={suggestion.id} className="flex flex-row items-center inline-block px-3 py-3">
        <div className="flex-shrink-0">
          <Image
            source={getAvatarURL(suggestion.id, 64)}
            imageClasses={imageClasses}
            loader={loadingPhoto}
            unloader={null}
            transform={{
              fit: 'cover', we: '', width: 32, height: 32,
            }}
            attributes={{ alt: label }}
            lazyLoadOffset={600}
          />
        </div>
        <div className="flex flex-col w-full pl-1">
          <div className="text-sm font-semibold text-gray-700 suggestion-label dark:text-dark-top-light">{label}</div>
          <div className="text-xs text-gray-600 dark:text-dark-top-medium">{description}</div>
        </div>
      </div>
    );
  };

  const onSuggestionsFetchRequested = async ({ value }): Promise<void> => {
    const topics = await autocompleteTopics({
      query: value,
      language: interfaceLanguage,
      count: 10,
      field: ['id', 'label', 'description'],
    });
    setSuggestions(topics || []);
  };

  const onSuggestionsClearRequested = (): void => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (_, {
    suggestion,
  }): void => {
    const link = getLinkProps(router.query, { id: suggestion.id }, '/topics/[id]');
    setQuery('');
    blurSearch();
    router.push(link.href, link.as).then(() => window.scrollTo(0, 0));
  };

  return (
    <form onSubmit={handleSubmit} action="">
      <div className="relative">
        <span>
          <Autosuggest
            inputProps={inputProps}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            focusInputOnSuggestionClick={false}
          />
        </span>
        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
          <SearchIcon className="w-4 h-4 text-gray-600 pointer-events-none fill-current dark:text-dark-top-medium" />
        </div>
        <div role="button" className={`absolute outline-none inset-y-0 right-0 flex items-center pr-4 cursor-pointer hidden ${displayClose || isSearchFocused ? 'sm:hidden' : 'sm:flex'}`} onClick={focusSearch} onKeyPress={focusSearch} tabIndex={0}>
          <SearchKeySlash className="w-6 h-6 text-gray-700 fill-current dark:text-dark-top-medium" />
        </div>
        <div role="button" className={`absolute ouline-none inset-y-0 right-0 flex items-center pr-4 cursor-pointer ${displayClose ? 'block' : 'hidden'}`} onClick={handleReset} onKeyPress={handleReset} onMouseDown={(event): void => { event.preventDefault(); }} tabIndex={0}>
          <CloseSolid className="w-4 h-4 text-gray-500 fill-current dark:text-dark-top-medium" />
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
