import React from 'react';
import LocaleSwitcher from './LocaleSwitcher';

interface Props {
  isOpen: boolean;
}

const Menu: React.FunctionComponent<Props> = ({ isOpen }) => (
  <div id="menu" className={`z-10 ${isOpen ? 'block' : 'hidden'}`}>
    <div className="px-3 py-4 border-t-2 border-gray-300 shadow dark:border-dark-light dark:bg-dark-medium">
      <LocaleSwitcher />
    </div>
  </div>
);

export default Menu;
