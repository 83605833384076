import React from 'react';
import { NextPage, NextPageContext } from 'next';
import { Language, Country } from 'core/types';
import { LocaleProvider } from 'context/LocaleContext';
import {
  getInitialLocale,
} from 'translations/getInitialLocale';

interface Props {
  interfaceLanguage?: Language;
  country?: Country[];
  contentLanguage?: Language[];
}

export default (WrappedPage: NextPage<any>) => {
  const WithLocale: NextPage<any, Props> = ({
    interfaceLanguage, country, contentLanguage, ...pageProps
  }) => (
    <LocaleProvider
      initialInterfaceLanguage={interfaceLanguage}
      initialCountry={country}
      initialContentLanguage={contentLanguage}
    >
      <WrappedPage {...pageProps} />
    </LocaleProvider>
  );
  WithLocale.getInitialProps = async (ctx: NextPageContext) => {
    let pageProps = {};
    if (WrappedPage.getInitialProps) {
      pageProps = await WrappedPage.getInitialProps(ctx);
    }
    const { country, contentLanguage, interfaceLanguage } = await getInitialLocale(ctx);
    return {
      ...pageProps, interfaceLanguage, country, contentLanguage,
    };
  };

  return WithLocale;
};
