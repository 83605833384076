import React from 'react';
import useLocale from 'hooks/useLocale';
import Flags from './Flags';

interface Props {
  isOpen: boolean;
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

const MenuButtons: React.FunctionComponent<Props> = ({ toggleMenu }) => {
  const { country } = useLocale();

  return (
    <div className="flex items-center justify-center px-3">
      <button id="menu-buttons" type="button" className="inline-flex items-center block text-gray-500 dark:text-dark-top-medium sm:flex focus:outline-none focus:text-gray-700" onClick={toggleMenu}>
        <Flags countries={country} maxCountries={5} className="hidden lg:inline-flex" />
        <Flags countries={country} maxCountries={1} className="lg:hidden" />
      </button>
    </div>
  );
};

export default MenuButtons;
