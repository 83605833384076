import React from 'react';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import useLocale from 'hooks/useLocale';
import {
  languages, languageNames, countries, Country, Language,
} from 'core/types';
import {
  capitalize,
  getAsArray,
  getAsCountryArray,
  removeFromArray,
  getAsLanguageArray,
} from 'core/utils';
import SelectIcon from '../public/images/icons/select-arrow.svg';
import { getFlag } from './Flags';

const LocaleSwitcher: React.FC = () => {
  const router = useRouter();
  const {
    interfaceLanguage,
    setInterfaceLanguage,
    country,
    setCountry,
    contentLanguage,
    setContentLanguage,
    t,
  } = useLocale();

  const handleInterfaceLanguageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setCookie(null, 'interfaceLanguage', JSON.stringify(e.target.value), {
        path: '/',
      });
      setInterfaceLanguage(e.target.value);
    },
    [router],
  );

  const handleCountryChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let current = getAsCountryArray(getAsArray(router.query.country));
      if (e.target.checked) {
        if (e.target.value === 'WORLD') {
          current = [];
        } else {
          current.push(e.target.value as Country);
        }
      } else {
        removeFromArray(current, e.target.value);
      }
      setCookie(null, 'country', JSON.stringify(current), {
        path: '/',
      });
      setCountry(current);
    },
    [router],
  );

  const handleContentLanguageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let current = getAsLanguageArray(getAsArray(router.query.contentLanguage));
      if (e.target.checked) {
        if (e.target.value === 'ALL') {
          current = [];
        } else {
          current.push(e.target.value as Language);
        }
      } else {
        removeFromArray(current, e.target.value);
      }
      setCookie(null, 'contentLanguage', JSON.stringify(current), {
        path: '/',
      });
      setContentLanguage(current);
    },
    [router],
  );

  return (
    <form>
      <div className="text-sm font-bold tracking-wide text-gray-600 uppercase dark:text-dark-top-medium">
        {t('interface language')}
      </div>
      <div className="relative inline-block ml-2">
        <select className="inline-block py-1 pr-6 text-sm font-medium text-gray-600 bg-transparent appearance-none dark:text-dark-top-light focus:outline-none" value={interfaceLanguage} onChange={handleInterfaceLanguageChange}>
          {languages.map((l) => (
            <option key={l} value={l}>
              {capitalize(languageNames[l])}
            </option>
          ))}
        </select>
        <span className="absolute inset-y-0 right-0 flex items-center py-2 text-gray-600 pointer-events-none dark:text-dark-top-medium">
          <SelectIcon className="w-4 h-4 fill-current" />
        </span>
      </div>

      <div className="mt-4 text-sm font-bold tracking-wide text-gray-600 uppercase dark:text-dark-top-medium">
        {t('content language')}
      </div>
      <div className="relative inline-block ml-2">
        {[null].concat(languages).map((l) => {
          const value = l || 'ALL';
          return (
            <label key={value} htmlFor={value} className="flex items-center">
              <input id={value} key={value} value={value} onChange={handleContentLanguageChange} checked={(contentLanguage.includes(value) || (value === 'ALL' && contentLanguage.length === 0))} className="mr-2 leading-tight" type="checkbox" />
              <span className="inline-flex items-center py-1 pr-6 text-sm font-medium text-gray-600 bg-transparent appearance-none dark:text-dark-top-light focus:outline-none">
                {capitalize(value === 'ALL' ? t('all') : languageNames[value])}
              </span>
            </label>
          );
        })}
      </div>

      <div className="mt-4 text-sm font-bold tracking-wide text-gray-600 uppercase dark:text-dark-top-medium">
        {t('countries')}
      </div>
      <div className="relative inline-block ml-2">
        {[null].concat(countries).map((c) => {
          const value = c || 'WORLD';
          return (
            <label key={value} htmlFor={value} className="flex items-center">
              <input id={value} key={value} value={value} onChange={handleCountryChange} checked={(country.includes(value) || (value === 'WORLD' && country.length === 0))} className="mr-2 leading-tight" type="checkbox" />
              <span className="inline-flex items-center py-1 pr-6 text-sm font-medium text-gray-600 bg-transparent appearance-none dark:text-dark-top-light focus:outline-none">
                {getFlag(c, 'w-6 h-6 text-indigo-500 shadow-inner border border-white dark:border-dark-light rounded-full fill-current mr-1')}
                {t(value)}
              </span>
            </label>
          );
        })}
      </div>

    </form>
  );
};

export default LocaleSwitcher;
