import { useContext } from 'react';
import { LocaleContext } from 'context/LocaleContext';
import { Translations } from 'core/types';
import { translate, translateLabels } from 'core/utils';

export default function useLocale(): Record<string, any> {
  const {
    interfaceLanguage,
    setInterfaceLanguage,
    country,
    setCountry,
    contentLanguage,
    setContentLanguage,
  } = useContext(LocaleContext);

  function t(key: string): string {
    return translate(key, interfaceLanguage);
  }

  function translateLabel(labels: Translations): string {
    return translateLabels(labels, interfaceLanguage);
  }

  return {
    t,
    translateLabel,
    interfaceLanguage,
    setInterfaceLanguage,
    country,
    setCountry,
    contentLanguage,
    setContentLanguage,
  };
}
