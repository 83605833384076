import React, { useState, useCallback } from 'react';
import { useRouter } from 'next/router';

import {
  getLastValue, isChildElement, getLinkProps,
} from 'core/utils';
import useKeyPress from 'hooks/useKeyPress';
import Logo from './logo';
import SearchBar from './SearchBar';
import Menu from './menu';
import MenuButton from './MenuButton';

const Header: React.FC = () => {
  const router = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [query, setQuery] = useState(getLastValue(router.query.query) || '');

  const focusSearch = (): void => {
    const searchInput = document.getElementById('search');
    if (searchInput) {
      searchInput.focus();
    }
  };

  const blurSearch = (): void => {
    const searchInput = document.getElementById('search');
    if (searchInput) {
      searchInput.blur();
    }
  };

  useKeyPress('/', () => { /**/ }, () => { focusSearch(); });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    blurSearch();
    const searchLinkProps = getLinkProps(router.query, { query }, '/search');
    router.push(
      searchLinkProps.href,
      searchLinkProps.as,
    );
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setQuery(event.target.value);
  };

  const onSearchFocus = (): void => {
    setIsSearchFocused(true);
    const logo = document.getElementById('logo');
    if (logo) {
      logo.classList.remove('block');
      logo.classList.add('hidden');
    }

    const menuButtons = document.getElementById('menu-buttons');
    if (menuButtons) {
      menuButtons.classList.remove('block');
      menuButtons.classList.add('hidden');
    }
  };

  const onSearchBlur = (): void => {
    setIsSearchFocused(false);
    const logo = document.getElementById('logo');
    if (logo) {
      logo.classList.remove('hidden');
      logo.classList.add('block');
    }

    const menuButtons = document.getElementById('menu-buttons');
    if (menuButtons) {
      menuButtons.classList.remove('hidden');
      menuButtons.classList.add('block');
    }
  };

  React.useEffect(() => {
    if (router.query.query !== undefined) {
      setQuery(getLastValue(router.query.query));
    }
  }, [router.query.query]);

  const handleReset = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setQuery('');
    const searchInput = document.getElementById('search');
    if (searchInput) {
      searchInput.focus();
    }
  };

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutsideMenu = useCallback((event) => {
    const menu = document.getElementById('menu');
    if (!isChildElement(event.target, menu)) {
      setIsMenuOpen(false);
    }
  }, [setIsMenuOpen]);

  React.useEffect(() => {
    if (isMenuOpen) {
      window.addEventListener('click', handleClickOutsideMenu);
    } else {
      window.removeEventListener('click', handleClickOutsideMenu);
    }

    return (): void => window.removeEventListener('click', handleClickOutsideMenu);
  }, [isMenuOpen]);

  return (
    <div id="header">
      <div className="">
        <div className="inset-x-0 top-0 z-20 flex items-center bg-gray-300 border-b-2 border-none dark:bg-dark-deep">
          <div className="relative w-full mx-4 mx-auto">
            <div className="flex flex-col justify-center h-20">
              <div className="flex items-center">
                <div className="px-3">
                  <div id="logo" className="flex items-center block sm:block">
                    <Logo />
                  </div>
                </div>
                <div className="flex flex-grow">
                  <div className="w-full">
                    <SearchBar
                      query={query}
                      handleSubmit={handleSubmit}
                      handleOnChange={handleOnChange}
                      handleReset={handleReset}
                      setQuery={setQuery}
                      focusSearch={focusSearch}
                      blurSearch={blurSearch}
                      onSearchFocus={onSearchFocus}
                      onSearchBlur={onSearchBlur}
                      isSearchFocused={isSearchFocused}
                    />
                  </div>
                  <MenuButton isOpen={isMenuOpen} toggleMenu={toggleMenu} />
                </div>
              </div>
            </div>
            <Menu isOpen={isMenuOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
