import React, { ReactElement } from 'react';
import { Country } from 'core/types';
import SelectIcon from '../public/images/icons/select-arrow.svg';
import FlagAll from '../public/images/flags/all.svg';
import FlagBE from '../public/images/flags/be.svg';
import FlagCH from '../public/images/flags/ch.svg';
import FlagFR from '../public/images/flags/fr.svg';
import FlagGB from '../public/images/flags/gb.svg';
import FlagUS from '../public/images/flags/us.svg';
import FlagZA from '../public/images/flags/za.svg';
import FlagCA from '../public/images/flags/ca.svg';
import FlagAU from '../public/images/flags/au.svg';
import FlagIN from '../public/images/flags/in.svg';
import FlagIL from '../public/images/flags/il.svg';

interface Props {
  countries: Country[];
  maxCountries?: number;
  className?: string;
}

export const getFlag = (country: Country, className: string): ReactElement => {
  if (country === 'BE') {
    return <FlagBE key={country} className={className} />;
  }
  if (country === 'CH') {
    return <FlagCH key={country} className={className} />;
  }
  if (country === 'FR') {
    return <FlagFR key={country} className={className} />;
  }
  if (country === 'GB') {
    return <FlagGB key={country} className={className} />;
  }
  if (country === 'US') {
    return <FlagUS key={country} className={className} />;
  }
  if (country === 'ZA') {
    return <FlagZA key={country} className={className} />;
  }
  if (country === 'CA') {
    return <FlagCA key={country} className={className} />;
  }
  if (country === 'AU') {
    return <FlagAU key={country} className={className} />;
  }
  if (country === 'IN') {
    return <FlagIN key={country} className={className} />;
  }
  if (country === 'IL') {
    return <FlagIL key={country} className={className} />;
  }
  return <FlagAll key="ALL" className={className} />;
};

const getFlags = (countries: Country[], maxCountries: number, className: string): ReactElement => {
  if (countries.length === 0 || countries.length > maxCountries) {
    return <FlagAll className={className} />;
  }

  return (
    <>
      {countries.sort().map((country) => (
        getFlag(country, className)
      ))}
    </>
  );
};

const Flags: React.FunctionComponent<Props> = (
  {
    countries,
    maxCountries = 1,
    className = '',
  },
): ReactElement => (
  <div className={`inline-flex items-center text-gray-500 dark:text-dark-top-medium ${className}`}>
    {getFlags(countries, maxCountries, 'w-6 h-6 text-indigo-500 dark:text-indigo-300 shadow-inner border border-white dark:border-dark-light rounded-full fill-current mr-1')}
    <SelectIcon className="w-4 h-4 fill-current" />
  </div>
);

export default Flags;
