import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useLocale from 'hooks/useLocale';
import { Country, countryLanguages, Language } from 'core/types';
import { capitalizeFirstLetter, getLinkProps } from 'core/utils';
import { getFlag } from './Flags';

const Footer: React.FunctionComponent = (): ReactElement => {
  const { t } = useLocale();
  const router = useRouter();

  const getEdition = (
    country: Country,
    languages: Language[],
  ): ReactElement => (
    <>
      {languages.map((language) => (
        <div className="flex w-full py-1 md:w-1/2 xl:w-1/3">
          <a href={`/${country}/${language}/`}>
            <span>
              {getFlag(
                country as Country,
                'w-6 h-6 text-indigo-500 dark:border-dark-light dark:text-indigo-300 shadow-inner border border-white rounded-full fill-current inline-block',
              )}
            </span>
            <span className="inline-block ml-2 text-sm tracking-wide text-gray-500 dark:text-dark-top-light">
              {`${t(country)} ${
                languages.length > 1
                  ? ` (${capitalizeFirstLetter(t(language))})`
                  : ''
              }`}
            </span>
          </a>
        </div>
      ))}
    </>
  );

  const getEditions = (): ReactElement => (
    <>
      {Object.keys(countryLanguages).map((c) => getEdition(c as Country, countryLanguages[c]))}
    </>
  );

  return (
    <div
      id="footer"
      className="p-4 mt-10 bg-gray-800 border-t-2 border-gray-300 dark:bg-dark-medium dark:border-dark-light"
    >
      <div className="flex flex-wrap">
        <div className="flex flex-col flex-shrink-0 w-full pb-6 sm:pb-0 sm:w-1/2">
          <div className="block text-sm font-bold tracking-wide text-gray-400 uppercase dark:text-dark-top-medium">
            {t('editions')}
          </div>
          <div className="flex flex-wrap">{getEditions()}</div>
        </div>

        <div className="flex flex-col flex-shrink-0 w-full sm:w-1/2">
          <div className="block text-sm font-bold tracking-wide text-gray-400 uppercase dark:text-dark-top-medium">
            {t('company')}
          </div>
          <div className="py-1 text-sm tracking-wide text-gray-500 dark:text-dark-top-light">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/knowledia"
            >
              {capitalizeFirstLetter(t('about us'))}
            </a>
          </div>
          <div className="py-1 text-sm tracking-wide text-gray-500 dark:text-dark-top-light">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://knowledia.com/presentations/index.html"
            >
              {capitalizeFirstLetter(t('vision'))}
            </a>
          </div>
          <div className="py-1 text-sm tracking-wide text-gray-500 dark:text-dark-top-light">
            <a href="mailto:contact@knowledia.com">
              {capitalizeFirstLetter(t('contact us'))}
            </a>
          </div>
          <div className="py-1 text-sm tracking-wide text-gray-500 dark:text-dark-top-light">
            <Link
              href={getLinkProps(router.query, {}, '/privacy').href}
              as={getLinkProps(router.query, {}, '/privacy').as}
            >
              <a>{capitalizeFirstLetter(t('privacy_policy'))}</a>
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-4 text-xs text-center text-gray-600 uppercase dark:text-dark-top-medium">
        {`© ${new Date().getFullYear()} Knowledia - All rights reserved`}
      </div>
    </div>
  );
};

export default Footer;
