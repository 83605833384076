import React from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import useLocale from "hooks/useLocale";
import {
  capitalize,
  capitalizeFirstLetter,
  getAsCountryArray,
  getAsArray,
  getAdsProvider,
} from "core/utils";
import { ENABLE_ADS } from "core/configs";
import Header from "./header";
import Footer from "./footer";
import {
  SimplePublift,
  DESKTOP_FOOTER_STICKY,
  MOBILE_FOOTER_STICKY,
} from "./Publift";

interface Props {
  title?: string;
  description?: string;
  indexOnlyIfCountry?: boolean;
}

const getFontCss = (): string => `@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Thin.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Thin.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Inter/Inter-ExtraLight.woff2") format("woff2"),
         url("/fonts/Inter/Inter-ExtraLight.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Light.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Light.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Regular.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Regular.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Medium.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Medium.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("/fonts/Inter/Inter-SemiBold.woff2") format("woff2"),
         url("/fonts/Inter/Inter-SemiBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Bold.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Bold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("/fonts/Inter/Inter-ExtraBold.woff2") format("woff2"),
         url("/fonts/Inter/Inter-ExtraBold.woff") format("woff");
  }
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("/fonts/Inter/Inter-Black.woff2") format("woff2"),
         url("/fonts/Inter/Inter-Black.woff") format("woff");
  }`;

const Layout: React.FunctionComponent<Props> = ({
  children,
  title = "",
  description = "",
  indexOnlyIfCountry = false,
}) => {
  const router = useRouter();
  const { t } = useLocale();
  const adsProvider = getAdsProvider(router.query);

  React.useEffect(() => {
    if (ENABLE_ADS && adsProvider === "publift") {
      if (window.fusetag) {
        window.fusetag.loadSlots();
      }
    }
  });

  return (
    <>
      <div className="overflow-x-hidden">
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimal-ui"
          />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/images/favicons/favicon-16x16.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/images/favicons/favicon-32x32.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/images/favicons/apple-touch-icon.png"
          />
          <link
            rel="mask-icon"
            href="/images/favicons/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <link rel="manifest" href="/misc/site.webmanifest" />
          <link rel="preconnect" href="https://www.google-analytics.com" />
          {ENABLE_ADS && adsProvider === "adsense" ? (
            <link
              rel="preconnect"
              href="https://pagead2.googlesyndication.com"
            />
          ) : null}
          {ENABLE_ADS && adsProvider === "publift" ? (
            <link rel="preconnect" href="https://cdn.fuseplatform.net" />
          ) : null}
          <meta name="msapplication-TileColor" content="#2b5797" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="supported-color-schemes" content="light dark" />
          <meta name="color-scheme" content="light dark" />
          <meta
            key="description"
            name="description"
            content={
              description || capitalizeFirstLetter(t("knowledia description"))
            }
          />
          {indexOnlyIfCountry &&
          getAsCountryArray(getAsArray(router.query.country)).length !== 1 ? (
            <meta key="robots" name="robots" content="noindex,nofollow" />
          ) : (
            <meta key="robots" name="robots" content="index,follow" />
          )}
          <style type="text/css">{getFontCss()}</style>
          <title key="title">{title || capitalize(t("knowledia news"))}</title>
          {ENABLE_ADS && adsProvider === "publift" ? (
            <>
              <script
                async
                src="https://cdn.fuseplatform.net/publift/tags/2/2479/fuse.js"
              />
              <script
                async
                src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
              />
              <script>
                {`
                window.googletag = window.googletag || { cmd: [] };
                googletag.cmd.push(function () {
                  var slot = googletag.defineOutOfPageSlot('/71161633,22508940639/KNOWLEDIA_knowledia/mweb_interstitial', googletag.enums.OutOfPageFormat.INTERSTITIAL);
                  if (slot) {
                    slot.addService(googletag.pubads());
                    googletag.pubads().disableInitialLoad();
                    googletag.enableServices();
                    googletag.display(slot);
                    googletag.pubads().refresh([slot]);
                  }
                });
              `}
              </script>
            </>
          ) : null}
        </Head>
        {ENABLE_ADS && adsProvider === "adsense" ? (
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          />
        ) : null}
        <div className="flex flex-col min-h-screen">
          <Header />
          <div className="flex-grow">{children}</div>
          <Footer />
        </div>
      </div>
      {ENABLE_ADS && adsProvider === "publift" ? (
        <div className="fuse_sticky">
          <SimplePublift yMargin={0} fuseId={MOBILE_FOOTER_STICKY} />
          <SimplePublift yMargin={0} fuseId={DESKTOP_FOOTER_STICKY} />
        </div>
      ) : null}
    </>
  );
};

export default Layout;
