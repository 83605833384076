import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { getLinkProps } from 'core/utils';
import LogoFull from '../public/images/logo-full.svg';
import LogoIcon from '../public/images/logo-icon.svg';

const Logo: React.FC = () => {
  const router = useRouter();
  const homeLinkProps = getLinkProps(router.query, {}, '/');

  return (
    <Link href={homeLinkProps.href} as={homeLinkProps.as}>
      <a>
        <LogoFull className="hidden w-auto md:h-8 md:block dark:fill-current dark:text-dark-top-light" />
        <LogoIcon className="block w-10 h-10 md:hidden" />
      </a>
    </Link>
  );
};

export default Logo;
