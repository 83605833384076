import React from 'react';
import { useRouter } from 'next/router';
import { timeFormatDefaultLocale } from 'd3-time-format';
import { defaultInterfaceLanguage } from 'translations/config';
import { Language, Country } from 'core/types';
import {
  getAsArray,
  getAsCountryArray,
  getAsLanguageArray,
  sameValuesArrays,
  getLinkProps,
  getLastValue,
} from 'core/utils';

interface ContextProps {
  readonly interfaceLanguage: Language;
  readonly setInterfaceLanguage: (language: Language) => void;
  readonly country: Country[];
  readonly setCountry: (country: Country[]) => void;
  readonly contentLanguage: Language[];
  readonly setContentLanguage: (contentLanguage: Language[]) => void;
}

export const LocaleContext = React.createContext<ContextProps>({
  interfaceLanguage: defaultInterfaceLanguage,
  setInterfaceLanguage: () => null,
  country: [],
  setCountry: () => null,
  contentLanguage: [],
  setContentLanguage: () => null,
});

interface Props {
  initialInterfaceLanguage: Language;
  initialCountry: Country[];
  initialContentLanguage: Language[];
}

export const LocaleProvider:
React.FC<Props> = (
  {
    initialInterfaceLanguage, initialCountry, initialContentLanguage, children,
  },
) => {
  const [interfaceLanguage, setInterfaceLanguage] = React.useState(initialInterfaceLanguage);
  const [country, setCountry] = React.useState(initialCountry);
  const [contentLanguage, setContentLanguage] = React.useState(initialContentLanguage);
  const router = useRouter();

  React.useEffect(() => {
    if (getLastValue(router.query.interfaceLanguage) !== interfaceLanguage) {
      router.query.interfaceLanguage = interfaceLanguage;
      const linkProps = getLinkProps(
        router.query, router.query, router.pathname,
      );
      router.replace(linkProps.href, linkProps.as);
    }

    if (interfaceLanguage === 'fr') {
      timeFormatDefaultLocale(
        {
          dateTime: '%A, le %e %B %Y, %X',
          date: '%d/%m/%Y',
          time: '%H:%M:%S',
          periods: ['AM', 'PM'],
          days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
          shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
          months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
          shortMonths: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
        },
      );
    } else {
      timeFormatDefaultLocale(
        {
          dateTime: '%x, %X',
          date: '%-m/%-d/%Y',
          time: '%-I:%M:%S %p',
          periods: ['AM', 'PM'],
          days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
      );
    }
  }, [interfaceLanguage]);

  React.useEffect(() => {
    if (!sameValuesArrays(getAsCountryArray(getAsArray(router.query.country)), country)) {
      router.query.country = country;
      router.query.interfaceLanguage = interfaceLanguage;
      const linkProps = getLinkProps(router.query, router.query, router.pathname);
      router.replace(linkProps.href, linkProps.as);
    }
  }, [country]);

  React.useEffect(() => {
    if (!sameValuesArrays(
      getAsLanguageArray(getAsArray(router.query.contentLanguage)), contentLanguage,
    )) {
      router.query.contentLanguage = contentLanguage;
      router.query.interfaceLanguage = interfaceLanguage;
      const linkProps = getLinkProps(router.query, router.query, router.pathname);
      router.replace(linkProps.href, linkProps.as);
    }
  }, [contentLanguage]);

  return (
    <LocaleContext.Provider
      value={{
        interfaceLanguage,
        setInterfaceLanguage,
        country,
        setCountry,
        contentLanguage,
        setContentLanguage,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
