import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { ENABLE_ADS } from 'core/configs';
import { getAdsProvider } from 'core/utils';

export const ARTICLE_HEADER = '22489145611';
export const ARTICLE_IN_CONTENT_1 = '22489145605';
export const ARTICLE_IN_CONTENT_2 = '22489145614';
export const ARTICLE_IN_CONTENT_3 = '22489145404';
export const ARTICLE_FOOTER = '22489145617';
export const MOBILE_FOOTER_STICKY = '22489145578';
export const DESKTOP_FOOTER_STICKY = '22489145575';
export const HOME_HEADER = '22489145563';
export const HOME_IN_CONTENT_1 = '22489335638';
export const HOME_IN_CONTENT_2 = '22489145566';
export const HOME_IN_CONTENT_3 = '22489145569';
export const HOME_IN_CONTENT_4 = '22489145572';
export const HOME_IN_CONTENT_5 = '22489145392';
export const HOME_FOOTER = '22489145581';

declare global {
  interface Window {
    fusetag: any;
  }
}

interface SimpleProps {
  className?: string;
  yMargin?: number;
  fuseId?: string;
}

export const SimplePublift: React.FC<SimpleProps> = ({
  className = '', yMargin = 2, fuseId = '',
}): ReactElement => {
  const router = useRouter();
  const adsProvider = getAdsProvider(router.query);

  if (!ENABLE_ADS || adsProvider !== 'publift') {
    return null;
  }

  return (
    <div className={`my-${yMargin} flex w-full items-center justify-center ${className}`}>
      <div key={fuseId} data-fuse={fuseId} />
    </div>
  );
};
